import { Link, useNavigate } from "react-router-dom";
import styles from "./TopicsItem.module.scss";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import { useIsAuthenticated } from "@podcasts/hooks";

export function TopicsListItem({
  title,
  podcasts_count,
  slug,
  image,
  saved,
  type = "topics",
  description,
  bio,
  onToggleFavorite = () => {},
}: any) {

  const isAuthenticated = useIsAuthenticated();
  //   const navigate = useNavigate();

  //   const isFullBoxClickable = ["courses", "topics", "speakers"].includes(type);

  return (
    <Link className={`${styles["topic-list-wrapp"]}`}  to={`/podcasts/topics/${slug}`}>
      <div className="d-flex justify-content-between">
        <div >
          <h6>{title}</h6>
          <div className={`${styles["description"]}`}>
            <PodcastCount count={podcasts_count}/>
            <p >{description ?? bio}</p>
          </div>
        </div>
        <div>
          {isAuthenticated && (
            <button
              onClick={onToggleFavorite}>
              <i
                className={`${
                  saved ? "icon-bookmark-full" : "icon-bookmark-empty"
                }`}
              ></i> 
            </button>
          )}
        </div>
      </div>
    </Link>
  );
}

export default TopicsListItem;
