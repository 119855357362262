import { Link } from "react-router-dom";
import styles from "./SpeakerItem.module.scss";
import { UnauthorizedAction } from "../../../../../../components";
import PodcastCount from '../../../../../../components/PodcastCount/PodcastCount';

export function SpeakersItem({
  slug,
  avatar,
  fname,
  lname,
  title,
  saved,
  bio,
  podcasts_count,
  image,
  onToggleFavorite = () => {},
}: any) {
  return (
    <div className={`${styles["speakers-item"]}`}>
      <div
        className={`${styles["speakers-item-header"]} d-flex justify-content-between align-items-center`}
      >
        <div className={`${styles["speaker-info"]} d-flex align-items-center`}>
          <div className={`${styles["speaker-info-avatar"]}`}>
            <img src={image} alt="" />
          </div>
          <p>
            {title} {fname} {lname}
          </p>
        </div>
        <div
          className={`${styles["speaker-actions"]} d-flex align-items-center`}
        >
          <button className="normalize-btn">
            <UnauthorizedAction
              onClick={() => {
                onToggleFavorite();
              }}
            >
              <i
                className={`${
                  saved ? "icon-bookmark-full" : "icon-bookmark-empty"
                }`}
              ></i>
            </UnauthorizedAction>
          </button>
        </div>
      </div>
      <p className={`${styles["speakers-descr"]}`}>{bio}</p>
      <div
        className={`${styles["speakers-item-footer"]} d-flex justify-content-between align-items-center`}
      >
        <p><PodcastCount count={podcasts_count}/></p>
        <Link to={`/podcasts/speakers/${slug}`}>View speaker</Link>
      </div>
    </div>
  );
}

export default SpeakersItem;
