import { Link, useLocation } from "react-router-dom";
import AuthComponent from "./components/AuthComponent/AuthComponent";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LeftSidebar.module.scss";
import { DropDown, InputSelectV2 } from "../../../../components";
import { userSelector } from "@podcasts/store/user"
import { useIsAuthenticated } from "@podcasts/hooks";
import { levelOptions } from "@podcasts/constants";
import { changeLevel, levelSelector } from "@podcasts/store/search";

export function LeftSidebar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedLevel = useSelector(levelSelector);
  const { user } = useSelector(userSelector) as any;
  const isAuthenticated = useIsAuthenticated();

  const SidebarLinks = [
    {
      name: "Featured",
      path: "/podcasts/featured",
      icon: "icon-featured",
    },
    {
      name: "Courses",
      path: "/podcasts/courses",
      icon: "icon-series",
    },
    {
      name: "Speakers",
      path: "/podcasts/speakers",
      icon: "icon-empty-mic",
    },
    {
      name: "Topics",
      path: "/podcasts/topics",
      icon: "icon-topics",
    },
    // {
    //   name: "Search",
    //   path: "/podcasts/search",
    //   icon: "icon-search",
    // },
  ];
  const SidebarSubmenuLinks = [
    {
      name: "Saved Podcasts",
      path: "/podcasts/saved-podcasts",
    },
    {
      name: "Saved Topics",
      path: "/podcasts/saved-topics",
    },
    {
      name: "Saved Courses",
      path: "/podcasts/saved-courses",
    },
    {
      name: "Saved Speakers",
      path: "/podcasts/saved-speakers",
    },
    {
      name: "Recent",
      path: "/podcasts/recent",
    },
  ];

  const list = [
    {
      label: "Beginner",
      value: "beginner",
      onClick: () => {
        dispatch(changeLevel("beginner"));
        // navigate("/profile");
      },
    },
    {
      label: "Advanced",
      value: "advanced",
      onClick: () => {
        dispatch(changeLevel("advanced"));
        // navigate("/profile");
      },
    },
    // {
    //   label: "Log out",
    //   value: "Log out",
    //   onClick: async () => {
    //     await dispatch(logOut());
    //     dispatch(changeLevel("beginner"));
    //     navigate("/login");
    //   },
    // },
  ];
  console.log(selectedLevel);
  return (
    <div className={styles["left-sidebar"]}>
      <div className={styles["sidebar"]}>
        <div>
          <h6>Explore</h6>
          <DropDown
            list={list}
            className={`${styles["dropdown"]}`}
            onOptionClick={(value: any) => {}}
            child={<div>Viewing as a: <span>{`${selectedLevel}`}</span><i className="icon-select" /></div>}
          />
        </div>
        <ul className={styles["sidebar-list"]}>
          {SidebarLinks.map((l: any, i) => (
            <li
              key={i}
              className={`${l.path === pathname ? styles["active"] : ""}`}
            >
              <div className={styles["link-box"]}>
                <div className={styles["left-icon-box"]}>
                  <i className={l.icon}></i>
                </div>
                <Link to={l.path} className={styles["sidebar-link"]}>
                  {l.name}
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {isAuthenticated ? (
        <div className={styles["sidebar-submenu"]}>
          <p className={styles["sidebar-submenu-title"]}>
            <i className="icon-bookmark-empty"></i>
            <span>{user.fname}’s Library</span>
          </p>
          <ul className={styles["sidebar-submenu-list"]}>
            {SidebarSubmenuLinks.map((l: any, i) => (
              <li
                key={i}
                className={`${l.path === pathname ? styles["active"] : ""}`}
              >
                <Link to={l.path} className={styles["sidebar-submenu-link"]}>
                  {l.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <AuthComponent />
      )}
    </div>
  );
}

export default LeftSidebar;
