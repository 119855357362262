// import styles from './Footer.module.scss';
import React from "react";
import styles from "./Button.module.scss";

export function Button({
  name,
  onClick,
  icon = "icon-chevron-right",
  white = false,
  className = ""
}: {
  name: string;
  onClick: any;
  icon?: string;
  className?: string;
  white?: boolean;
}) {
  return (
    <button
      type="submit"
      className={`${styles["button"]}  ${white && styles["button-white"]} ${className}`}
      onClick={() => {
        onClick(onClick);
      }}
    >
      <p className={styles["button-text"]}>{name}</p>
      <span>
        <i className={icon}></i>
      </span>
    </button>
  );
}

export default Button;
