import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../Button/Button";
import { Popover } from "react-tiny-popover";
import { isEqual, debounce, chain, map } from "lodash";
import Filters from "../Filters/Filters";
import styles from "./SearchBar.module.scss";
import { useDispatch } from "react-redux";

export default function SearchBar({  
  speakers = [],
  topics = [],
  courses = [],
  className = "",
  getFilter
}: any) {
  const [keyword, setKeyword] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selected, setSelected] = useState([]) as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const onChangeKeyword = (e) => {
  //   setKeyword(e.target.value);
  // };

  const onChange = (option: any) => {
    setSelected((options: any) => {
      const index = selected.findIndex((o: any) => isEqual(o, option));
      if (index === -1) {
        return [...selected, option];
      }
      return options.filter((i: any) => !isEqual(i, option));
    });
  };


  const onSearch = () => {
    navigate("/podcasts/search", { state: { keyword, selected } });
  }

  const dispathFilter = (search, selected) => {
    const query: any = {};
    const result = chain(selected).groupBy("type").value();

    map(result, (v, k) => {
      query[k] = map(v, "id");
    });
    dispatch(getFilter({ search, ...query }));
  }
  const getFilterDebounced = useCallback(debounce(dispathFilter, 1100), []);

  useEffect(() => {
    getFilterDebounced(keyword, selected)
  }, [selected, keyword]);

  useEffect(() => {
    setSelected([]);
    setKeyword("");
  }, [location]);

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const onClickOutside = () => {
    // setSelected([]);
    // setSelectedTmp(selected);
    setIsPopoverOpen(false);
  }

  const onClear = () => {
    setSelected([]);
  }
  return <div className={`container ${styles["search-container"]} ${className}`}>
    <div className={`${styles["search-input"]} d-flex align-items-center`}>
      <i className="icon-search"></i>
      <input
        placeholder="Start typing to search"
        value={keyword}
        onChange={onChangeKeyword}
        onKeyDown={({ key, target: { value } }: any) => {
          if (key === "Enter") {
            // setSearch(value);
            onSearch();
          }
        }}
      />
    </div>
    <div className={`${styles["filters"]}`}>
      <span className="" >Filters</span>
      <Popover
        containerClassName={`${styles["popover"]}`}
        isOpen={isPopoverOpen}
        positions={[ "bottom", "left", "right"]} // preferred positions by priority
        onClickOutside={onClickOutside}
        align="end"
        content={<div><Filters 
          filterList={{ "speaker": {
            value: speakers,
            label: "Speaker"
          }, 
          course: {
            value: courses,
            label: "Series / Courses"
          }, "topic": {
            value: topics,
            label: "Topics"
          } }}
          onChange={onChange}
          selected={selected}
          onClear={onClear}
          onApply={onSearch}
        /></div>}
      >
        <button onClick={() => setIsPopoverOpen(true)}><i className="icon-filter"/></button>
      </Popover>
    </div>
    <div>
      <Button name="Search" className={`${styles["search-button"]}`} onClick={() => {onSearch()}} />
    </div>
  </div>
}