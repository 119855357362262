import { map, chain } from "lodash";
import { getFilters, getSearch } from "@podcasts/store/search"
import { searchSelector } from "@podcasts/store/search"
import { dashboardSelector } from "@podcasts/store/dashboard";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import { Pagination } from "../../../../components";
import PodcastsTable from "../../components/PodcastsTable/PodcastsTable";
import FeaturedItem from "../FeaturedPage/components/FeaturedItem/FeaturedItem";
import {
  savePodcast,
} from "@podcasts/store/dashboard";
import styles from "./SearchPage.module.scss";
import { usePrevious } from "../../../../hooks/usePrevious";
import { useLocation, useNavigate } from "react-router-dom";
// TO DO:
// Create search hook and use in web and app
export function SearchPage() {
  const per_page = 12;
  const [order_by, setOrder_by] = useState("title");
  const [order, setOrder] = useState("asc");
  const myRef: any = useRef(null);
  const dispatch = useDispatch();
  const [view, setView] = useState("grid");
  const { state, pathname } = useLocation();
  const [query, setQuery] = useState({});
  const navigate = useNavigate();
  const {
    courses,
    speakers,
    topics,
    result: { data = [], last_page, current_page },
    level
  } = useSelector(searchSelector) as any;

  const prevOrderBy = usePrevious(order_by);
  const prevOrder = usePrevious(order);
  const prevLevel = usePrevious(level);
  const { saved_podcasts_ids = [] } = useSelector(dashboardSelector) as any;

  useEffect(() => {
    const query = {};
    if (state && state.selected) {
      const result = chain(state.selected).groupBy("type").value();
  
      map(result, (v, k) => {
        query[k] = map(v, "id");
      });
    }
    dispatch(getFilters({ search: state?.keyword, ...query }));
    navigate(pathname, {});
  }, []);


  useEffect(() => {
    if ((prevOrderBy !== order_by) || (prevOrder !== order) || (prevLevel !== level)) {
      dispatch(getSearch({ ...query, order, order_by, page: 1, per_page, level }));
    }
  }, [order_by, order, level]);

  const onSearch = (params: any) => {
    const q = { ...params };
    setQuery(q);
    setOrder("asc");
    setOrder_by("title");
    dispatch(getSearch({ ...q, per_page, page: 1, order: "asc", order_by: "title", level }));
    executeScroll();
  };

  const isSaved = (id: any) => saved_podcasts_ids.includes(Number(id));

  const handleSortChange = (e: any) => {
    let sortBy = e.target.innerText.toLowerCase();
    if (sortBy === order_by) {
      let sortOrder = order === "asc" ? "desc" : "asc";
      setOrder(sortOrder);
    } else {
      setOrder_by(sortBy);
      setOrder("asc");
    }
  }

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <h2 className={`${styles["search-title"]}`}>Podcast Library</h2>
      <SearchInput
        courses={courses}
        speakers={speakers}
        view={view}
        topics={topics}
        onViewChange={() => {
          setView((v: string) => (v === "grid" ? "list" : "grid"));
        }}
        keyword={state?.keyword}
        defaultSelected={state?.selected}
        getFilter={getFilters}
        onSearchChange={(q: any) => {
          onSearch({ ...q });
        }}
      />
      {
        data.length ? (
          <>
            {view === "grid" && (
              <div className={`${styles["featured-items"]}`}>
                {data.map((p: any) => (
                  <FeaturedItem
                    key={p.id}
                    {...p}
                    saved={isSaved(p.id)}
                    onSave={() => {
                      dispatch(savePodcast(p));
                    }}
                  ></FeaturedItem>
                ))}
              </div>
            )}
            {view === "list" && (
              <PodcastsTable
                items={data}
                handleSortChange={handleSortChange} order_by={order_by} order={order}
              />
            )}
          </>
        ) : (
          <h3 className={`${styles["no-result"]}`}>No results. Please try a new search.</h3>
        )
      }
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          dispatch(getSearch({ ...query, page, order, order_by, per_page }));
          executeScroll();
        }}
      />
    </div>
  );
}

export default SearchPage;
