/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef, JSX } from "react";
import styles from "./DropDown.module.scss";


export const DropDown = ({ child, list, onOptionClick, className }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref: any = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowDropDown(false);
    }
  };

  return (
    <div className={`${styles["drop-down"]} ${className}`} ref={ref}>
      <section
        onClick={() => {
          setShowDropDown((prev) => !prev);
        }}
      >
        <>{child}</>
        {showDropDown}
        <ul style={showDropDown ? { display: "block" } : { display: "none" }}>
          {list.map((i: any, item: number) => (
            <li key={item} style={i.styles || {}}>
              <span
                style={i.style ? i.style : {}}
                //to={ i.link ? i.link : '#' }
                onClick={() => i.onClick()}
              >
                {i.label}
              </span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
