import axios from "axios";
const qs = require("qs");

export class SearchService {

  static async getFiltersApi(options) {
    return await axios.get(`search/filters?${qs.stringify(options)}`).then(d => d.data);
  }

  static async getPodcasts(options = {}) {
    return await axios.get(`podcasts?${qs.stringify(options)}`);
  }
}
