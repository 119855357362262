import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { DropDown } from "../../../../../../components/DropDown/DropDown";
import TopicsItem from "../../../TopicsPage/components/TopicsItem/TopicsItem";
import TopicsListItem from "../../../TopicsPage/components/TopicsItem/TopicsListItem";
import styles from "./SeriesListPage.module.scss";
import { Pagination } from "../../../../../../components";
import { useCoursesPage } from "@podcasts/hooks";
import { searchSelector } from "@podcasts/store/search"
import SearchInput from "../../../../../../components/SearchInput/SearchInput";

export function SeriesListPage() {
  const myRef: any = useRef(null);
    
  const { 
    data: { data, last_page, current_page },
    order,
    setOrder,
    isSavedCourse,
    onPageChange,
    onSaveCourse
  } = useCoursesPage({ per_page: 12, defaultOrder: "asc", order_by: "title" })

  const {
    courses,
    speakers,
    topics,
    // result: { data = [], last_page, current_page },
    // level
  } = useSelector(searchSelector) as any;

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });

  const [view, setView] = useState("grid");
  const [search, setSearch] = useState("");

  const onSearch = (q) => {
    setSearch(q.search);
    onPageChange(q.search, 1);
  }

  return (
    <>
      <div
        ref={myRef}
        className={`${styles["title-box"]} d-flex flex-column justify-content-between gap-10`}>
        <h2>Courses</h2>
        {/* <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div> */}
        {/* <SearchInput
          courses={courses}
          speakers={speakers}
          view={view}
          topics={topics}
          onViewChange={() => {
            setView((v: string) => (v === "grid" ? "list" : "grid"));
          }}
          onSearchChange={(q: any) => {
            setSearch(q.search);
          }}
          onSearch={onSearch}
          hideFilter={true}
        /> */}
      </div>
      {data.length ? (<>
        <div className={`${styles["topics-items"]}`}>
          {data.map((item: any, i: number) => (
            view === "list" ? (
              
              <TopicsListItem 
                {...item}
                type={"courses"}
                saved={isSavedCourse(item.id)}
                onToggleFavorite={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSaveCourse(item);
                }}/>)
              : (
                <div key={i} className={`${styles["topic-wrapp"]}`}>
                  <TopicsItem
                    {...item}
                    type={"courses"}
                    saved={isSavedCourse(item.id)}
                    onToggleFavorite={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onSaveCourse(item);
                    }}
                  /></div>)
          ))}
        </div>
        <Pagination
          pages={last_page}
          page={current_page}
          onPageChange={(page: number) => {
            onPageChange(page)
            executeScroll();
          }}
        />
      </>) : (<h3 className={`${styles["no-result"]}`}>No results. Please try a new search.</h3>) }
    </>
  );
}

export default SeriesListPage;
