import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import TopicsItem from "../../components/TopicsItem/TopicsItem";
import { Link } from "react-router-dom";
import { searchSelector } from "@podcasts/store/search"
import { Pagination } from "../../../../../../components";
import styles from "./TopicsAllPage.module.scss";
import { useTopicsPage } from "@podcasts/hooks";
import { useIsAuthenticated } from "@podcasts/hooks";
import SearchInput from "../../../../../../components/SearchInput/SearchInput";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import TopicsListItem from "../../components/TopicsItem/TopicsListItem";

export function TopicsAllPage({ title = "" }) {
  const myRef: any = useRef(null);    
  const { 
    data: { data, last_page, current_page },
    order,
    setOrder,
    isSavedTopic,
    onPageChange,
    onSaveTopic
  } = useTopicsPage({ per_page: 12, defaultOrder: "asc", order_by: "title" });

  const {
    courses,
    speakers,
    topics,
    // result: { data = [], last_page, current_page },
    // level
  } = useSelector(searchSelector) as any;

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });

  const [view, setView] = useState("grid");
  const [search, setSearch] = useState("");

  const onSearch = (q) => {
    setSearch(q.search);
    onPageChange(q.search, 1);
  }

  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <div
        ref={myRef}
        className={`${styles["title-box"]} d-flex flex-column justify-content-between gap-10`}
      >
        <h2>{title}</h2>
        {/* <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div> */}
        {/* <SearchInput
          courses={courses}
          speakers={speakers}
          view={view}
          topics={topics}
          onViewChange={() => {
            setView((v: string) => (v === "grid" ? "list" : "grid"));
          }}
          onSearchChange={(q: any) => {
            setSearch(q.search);
          }}
          onSearch={onSearch}
          hideFilter={true}
        /> */}
      </div>
      {data.length ? (<>
        <div className={`${styles["topics-items"]}`}>
          {view === "grid" && (<>
            {data.map((item: any, i: number) => (
              <div key={i} className={`${styles["topic-wrapp"]}`}>
                <TopicsItem
                  {...item}
                  saved={isSavedTopic(item.id)}
                  onToggleFavorite={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSaveTopic(item);
                  }}
                />
              </div>
            ))}</>
          )}
          {view === "list" && (
            <>{data.map((item: any, i: number) => (
              <TopicsListItem
                {...item}
                saved={isSavedTopic(item.id)}
                onToggleFavorite={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSaveTopic(item);
                }}
              />
            ))}</>
          )}
        </div>
        <Pagination
          pages={last_page}
          page={current_page}
          onPageChange={(page: number) => {
            onPageChange(search, page)
            executeScroll();
          }}
        />
      </>) : (
        <h3 className={`${styles["no-result"]}`}>No results. Please try a new search.</h3>
      )
      }
    </>
  )
}

export default TopicsAllPage;
