import { useCallback, useState, useEffect } from "react";
import { useSpeakerLibraryBase } from './library-base';
import { useSpeakers } from './api';
import { useSpeakerLibraryAppToast } from './useAppLibraryToast';
import { useSpeakerLibraryWebToast } from './useWebLibraryToast';


export const useSpeakersBasePage = (props) => {
    const {
      per_page = 12, 
      order_by = 'lname', 
      defaultOrder = 'asc',
      onAddFinish,
      onRemoveFinish,
      onUnathorized,
    } = props;
    const [page,setPage] = useState(1);
    const [order, setOrder] = useState(defaultOrder)
    const [search, setSearch] = useState('');
    const { isSavedSpeaker, toggleSpeakerLibrary, isAuthenticated }  = useSpeakerLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useSpeakers({ search, page, per_page, order_by, order });
  
 
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
  
  
    const onSaveSpeaker = useCallback((item) => {
      toggleSpeakerLibrary(item)
    },[isAuthenticated]);

    const onPageChange = useCallback((search, p) => {
      setSearch(search);
      setPage(p)
    },[])

    
    const toggleOrder = useCallback(() => {
      setOrder(order === "asc" ? "desc" : "asc");
      setPage(1)
    },[order])

    return {
        data,
        order,
        setOrder,
        isSavedSpeaker,
        toggleOrder,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange,
        onSaveSpeaker,
        isAuthenticated
    }
}

export const useSpeakersPage = ({ per_page = 12, order_by = 'lname', defaultOrder = 'asc' }) => {
    const toast = useSpeakerLibraryWebToast();
  return useSpeakersBasePage({ per_page, order_by, defaultOrder, ...toast })
}

export const useSpeakersAppPage = ({ per_page = 12, order_by = 'lname', defaultOrder = 'asc' }) => {
   const toast = useSpeakerLibraryAppToast();
   return useSpeakersBasePage({ per_page, order_by, defaultOrder, ...toast })
}