/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useState } from "react";
import Button from "../Button/Button";
import styles from "./SearchInput.module.scss";
import { map, isEqual, filter, chain, debounce } from "lodash";
import { Popover } from "react-tiny-popover";
import Filters from "../Filters/Filters";
import { useDispatch } from "react-redux";

export const SearchInput = ({
  speakers = [],
  topics = [],
  courses = [],
  onSearchChange,
  view = "list",
  onViewChange,
  defaultSelected = [],
  keyword = "",
  hideFilter = false,
  onSearch = null,
  getFilter = null
}: any) => {

  const dispatch = useDispatch();
  const [search, setSearch] = useState(keyword) as any;
  const [selected, setSelected] = useState(defaultSelected) as any;
  const [selectedTmp, setSelectedTmp] = useState(defaultSelected) as any;

  const onChange = (option: any) => {
    setSelectedTmp((options: any) => {
      const index = selectedTmp.findIndex((o: any) => isEqual(o, option));
      if (index === -1) {
        return [...selectedTmp, option];
      }
      return options.filter((i: any) => !isEqual(i, option));
    });
  };

  const dispathFilter = (selectedTmp, search) => {
    const query = buildQueryParams(selectedTmp);
    dispatch(getFilter({ search, ...query }));
  }

  const onRemoveTag = (option: any) => {
    onChange(option);
    
    setSelected((options: any) => {
      const index = selected.findIndex((o: any) => isEqual(o, option));
      if (index === -1) {
        return [...selected, option];
      }
      return options.filter((i: any) => !isEqual(i, option));
    });
    // dispathFilter(search);
    // onSearchLoal();
  }

  const getFilterDebounced = useCallback(debounce(dispathFilter, 1100), []);

  useEffect(() => {
    onSearchLoal(selected);
  }, [selected]);

  useEffect(() => {
    getFilterDebounced(selectedTmp, search);
  }, [selectedTmp, search]);

  const onApply = () => {
    setSelected(selectedTmp);
  }

  const buildQueryParams = (option) => {
    const query: any = {};
    const result = chain(option).groupBy("type").value();

    map(result, (v, k) => {
      query[k] = map(v, "id");
    });
    return query;
  }

  useEffect(() => {
    const query: any = {};
    if (search.length > 0) {
      query.search = search;
    }
    const result = chain(selected).groupBy("type").value();

    map(result, (v, k) => {
      query[k] = map(v, "id");
    });

    onSearchChange(query);
  }, []);

  const onSearchLoal = (selected) => {
    const query: any = buildQueryParams(selected);
    if (search.length > 0) {
      query.search = search;
    }
    setIsPopoverOpen(false);
    onSearchChange(query);
    if (onSearch) {
      onSearch(query);
    }
    // dispathFilter(search)
    
  }

  const onClear = () => {
    const query: any = {};
    if (search.length > 0) {
      query.search = search;
    }
    setSelected([]);
    setSelectedTmp([]);
    // onSearchChange(query);
  }

  const onClickOutside = () => {
    // setSelected([]);
    setSelectedTmp(selected);
    setIsPopoverOpen(false);
  }



  const onChangeKeyword = (e) => {
    setSearch(e.target.value);
    // getFilterDebounced(e.target.value);
  };
  // const toType = (type: string, option: any) => ({ ...option, type });

  const selectedIdsByType = (type: string) =>
    map(filter(selected, { type }), "id");

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div>
      <section className={`${styles["search-container"]}`}>
        <div className={`${styles["search-input"]} d-flex align-items-center`}>
          <i className="icon-search"></i>
          <input
            placeholder="Start typing to search"
            onChange={onChangeKeyword}
            value={search}
            onKeyDown={({ key, target: { value } }: any) => {
              if (key === "Enter") {
                //     setSearch(value);
                onSearchLoal(selected)
              }
            }}
          />
        </div>
        {/* <ul>
          <li>
            <MultiSelect
              label={"Speakers"}
              onChange={onChange}
              selected={selectedIdsByType("speaker")}
              options={speakers.map((i: any) => toType("speaker", i))}
            />
          </li>
          <li>
            <MultiSelect
              label={"Series / Courses"}
              onChange={onChange}
              selected={selectedIdsByType("course")}
              options={courses.map((i: any) => toType("course", i))}
            />
          </li>
          <li>
            <MultiSelect
              label={"Topics"}
              onChange={onChange}
              selected={selectedIdsByType("topic")}
              options={topics.map((i: any) => toType("topic", i))}
            />
          </li>
        </ul> */}
        <div className={`${styles["search-button-wrapper"]}`}>
          <Button name="Search" className={`${styles["search-button"]}`} onClick={onSearchLoal} />
        </div>
      </section>
      <div className={`${styles["control-actions"]}`}>
        {!hideFilter && (
          <div className={`${styles["filters"]}`}>
            <span className="" >Filters</span>
            <Popover
              containerClassName={`${styles["popover"]}`}
              isOpen={isPopoverOpen}
              positions={[ "bottom", "left", "right"]} // preferred positions by priority
              onClickOutside={onClickOutside}
              content={<div><Filters 
                filterList={{ "speaker": {
                  value: speakers,
                  label: "Speaker"
                }, 
                course: {
                  value: courses,
                  label: "Series / Courses"
                }, "topic": {
                  value: topics,
                  label: "Topics"
                } }}
                onChange={onChange}
                selected={selectedTmp}
                onClear={onClear}
                onApply={onApply}
              /></div>}
            >
              <button onClick={() => setIsPopoverOpen(true)}><i className="icon-filter"/></button>
            </Popover>
          </div>)}
        <div
          className={`${styles["view-box"]} d-flex align-items-center`}
          onClick={onViewChange}
        >
          <span>{view === "list" ? "Grid" : "List"} View</span>
          <i className={`icon-${view === "grid" ? "list" : "grid "}`}></i>
        </div>
      </div>
      <div className={`${styles["tag-wrapper"]}`}>
        {!!selectedIdsByType("speaker").length && (<div className={`${styles["tag-block"]}`}>
          <span className={`${styles["filter-title"]}`}>Speakers</span>
          <ul className={`${styles["tag_list"]} d-flex align-items-center`}>
            {filter(selected, { type: "speaker" }).slice(0, 2).map((o: any, i: number) => (
              <li key={i}>
                <span>{o.name}</span>
                <span
                  onClick={() => {
                    onRemoveTag(o);
                  }}
                  className={`${styles["close"]}`}
                >
              &times;
                </span>
              </li>
            ))}

            {selectedIdsByType("speaker").length > 2 && 
            <li>
              <span>+{selectedIdsByType("speaker").length - 2}</span>
            </li>
            }
          </ul>
        </div>)
        }
        {!!selectedIdsByType("course").length && (<div className={`${styles["tag-block"]}`}>
          <span className={`${styles["filter-title"]}`}>Series / Courses</span>
          <ul className={`${styles["tag_list"]} d-flex align-items-center`}>
            {filter(selected, { type: "course" }).slice(0, 2).map((o: any, i: number) => (
              <li key={i}>
                <span>{o.name}</span>
                <span
                  onClick={() => {
                    onRemoveTag(o);
                  }}
                  className={`${styles["close"]}`}
                >
              &times;
                </span>
              </li>
            ))}

            {selectedIdsByType("course").length > 2 && 
            <li>
              <span>+{selectedIdsByType("course").length - 2}</span>
            </li>
            }
          </ul>
        </div>)
        }
        {!!selectedIdsByType("topic").length && (<div className={`${styles["tag-block"]}`}>
          <span className={`${styles["filter-title"]}`}>Topics</span>
          <ul className={`${styles["tag_list"]} d-flex align-items-center`}>
            {filter(selected, { type: "topic" }).slice(0, 2).map((o: any, i: number) => (
              <li key={i}>
                <span>{o.name}</span>
                <span
                  onClick={() => {
                    onRemoveTag(o);
                  }}
                  className={`${styles["close"]}`}
                >
              &times;
                </span>
              </li>
            ))}

            {selectedIdsByType("topic").length > 2 && 
            <li>
              <span>+{selectedIdsByType("topic").length - 2}</span>
            </li>
            }
          </ul>
        </div>)
        }
      </div>
    </div>
  );
};

export default SearchInput;
