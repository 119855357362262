import { MultiSelect } from "../MultiSelect/MultiSelect";
import { map, filter } from "lodash";
import styles from "./Filters.module.scss";

export default function Filter({ filterList, selected, onChange, onClear, onApply }: any) {

  const selectedIdsByType = (type: string) =>
    map(filter(selected, { type }), "id");

  const toType = (type: string, option: any) => ({ ...option, type });

  return <div className={`${styles["filters-wrapper"]}`}>
    {Object.keys(filterList).map((key) => {
      return <div className={`${styles["filter-item"]}`} key={key}>
        <MultiSelect
          label={filterList[key].label}
          onChange={onChange}
          selected={selectedIdsByType(key)}
          options={filterList[key].value.map((i: any) => toType(key, i))}
        />
      </div>
    })}
    <div className={`${styles["control"]}`}>
      <button className={`btn btn-default ${styles["btn-clear"]}`} onClick={onClear}>Clear</button>
      <button className={`btn ${styles["btn-apply"]}`} onClick={onApply}>Apply</button>
    </div>
  </div>;
}