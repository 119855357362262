import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SearchService } from "@podcasts/api";
import { xor, omitBy, isNil } from "lodash";
import { levelValues } from '@podcasts/constants';

const initialState = {
  filters: {
    speaker: [],
    course: [],
    topic: [],
    search: "",
  },
  level: null,
  courses: [],
  speakers: [],
  topics: [],
  loaded: false,
  result: { data: [] },
};

export const getFilters = createAsyncThunk(
  "search/getFilters",
  async (options, { rejectWithValue }) => {
    try {
      const { courses, topics, speakers } = await SearchService.getFiltersApi(options);

      return { courses, topics, speakers };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await SearchService.getPodcasts(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeLevel = createAsyncThunk(
    "search/changeLevel",
    (level, { rejectWithValue }) => {
      try {
        return { level };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.result = { data: [], last_page: 0, current_page: 1 };
    },
    toggleFilter: (state, { payload }) => {
      const type = payload.type;
      let filters = state.filters[type];
      filters = xor(filters, [payload.id]);
      state.filters = { ...state.filters, [type]: filters };
    },
    searchKeyChange(state, { payload }) {
      const filters = { ...state.filters, search: payload };
      state.filters = omitBy(filters, isNil);
    },
  },
  extraReducers: {
    [getFilters.fulfilled]: (state, action) => {
      const { courses, topics, speakers } = action.payload;
      state.courses = courses;
      state.speakers = speakers;
      state.topics = topics;
      state.loaded = true;
    },
    [getSearch.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [changeLevel.fulfilled]: (state, action) => {
      state.level = action.payload.level;
    },
  },
});
export const { resetSearch, toggleFilter, searchKeyChange } =
  searchSlice.actions;
export const searchReducer = searchSlice.reducer;
